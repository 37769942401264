<template>
  <div v-if="$can('manage billing')" class="container-fluid">
    <div class="row">
      <div class="col-md-3 spark-settings-tabs h-100">
        <billing-nav class="h-100" :user="user" :path="path"></billing-nav>
      </div>
      <div class="col-md-9">
        <div class="card border-0 shadow-sm" v-if="user">
          <div class="card-header">
            <div class="row">
              <div class="col my-auto">
                <p class="alert alert-primary mb-2">
                  <span>Your Plan: <b>{{ user.clinic.billing_subscription.billing_product.title }}</b></span>
                  <span class="float-end">Renewal: <b>{{ user.clinic.billing_subscription.current_period_end | formatDate}}</b></span>
                </p>
              </div>
            </div>
          </div>
          <div class="card-body mb-4 pt-0">
            <h4 class="mb-1">Upgrade</h4>
            <div class="row">
              <div class="col mx-2">
                <pricing-table :user="user" :path="path"></pricing-table>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import PricingTable from "./partials/PricingTable.vue";
import BillingNav from "./partials/BillingNav";
export default {
  props: [],
  components: {
    BillingNav,
    PricingTable
  },
  filters: {
    formatDate(date) {
      return moment(date).format("LL");
    }
  },
  computed: {
    user() {
      return this.$store.getters['auth/user'];
    },
    path() {
      return this.$router.currentRoute.fullPath;
    },
  }
};
</script>

<style>
</style>
